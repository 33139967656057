<template>
  <div class="basic-message">
    <div class="address">
      <div
        class="address-container"
        v-for="item in staticInfo.addresses"
        :key="item.id"
      >
        <div class="address-pointer"></div>
        <div>{{ item.append }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    staticInfo: {
      type: Object,
    },
  },
  computed: {},
  methods: {},
  created() {},
}
</script>

<style scoped lang="scss">
.basic-message {
  background: url('~@/assets/images/mengzi/elderly/ResidentialAddress.png') 0 0
    no-repeat;
  background-size: 100% 100%;
  //   box-shadow: 0 0 0 0.5px #0d90ff, inset 0 0 30px rgba(13, 144, 255, 0.4);
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}
.address {
  width: 19rem;
  height: 7.9166667rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  border: 1px solid #213060;
  margin: 0 auto;
  margin-top: 1.8333333rem;
  padding-left: 1.41666667rem;
  box-sizing: border-box;
  overflow: auto;
  padding-right: 3.91666667rem;
  padding-top: 0.5rem;
  padding-bottom: 0.3rem;
  .address-container {
    font-size: 0.58333333rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8eb8e6;
    margin-top: 1rem;
    position: relative;
    &:nth-child(1) {
      margin-top: 0rem;
    }
    .address-pointer {
      position: absolute;
      height: 0.41666rem;
      width: 0.41666rem;
      background: #14c41c;
      border-radius: 50%;
      top: 0.25rem;
      left: -0.833rem;
    }
  }
  &::-webkit-scrollbar {
    width: 0;
  }
}
.el-table--striped .el-table__body tr.el-table__row--striped td {
  background-color: transparent;
}
</style>
