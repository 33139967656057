<template>
  <!-- 大屏上侧 -->
  <div class="viewport_cs">
    <div class="orderTop">
      <!-- <span>老人政采信息</span> -->
    </div>
    <div class="closeImage">
      <img
        src="@/assets/images/zhangzhe/zehngcai/let_icon.png"
        alt=""
        @click="back()"
      />
    </div>
    <div class="ds-guanbi" @click="buttoncli">
      <img v-if="isFullscreen" src="@/assets/images/top/guanbi.png" alt="" />
      <img v-else src="@/assets/images/top/dakai.png" alt="" />
    </div>
    <div class="ds-center-tip">
      <div class="elderlyTop">
        <div class="elderlyTop-left">
          <!-- 老人基础信息 -->
          <div>
            <basicInformation :staticInfo="staticInfo"></basicInformation>
          </div>
          <div class="elderlyTop-middle-top">
            <!-- 补助信息 -->
            <subsidyInformation></subsidyInformation>
          </div>
        </div>
        <div class="elderlyTop-middle-bottom">
          <!-- 柱状图 -->
          <Histogram></Histogram>
        </div>
      </div>
      <div class="elderlyTop-right">
        <!-- 折线图 -->
        <LineChart></LineChart>
      </div>
    </div>
    <div>
      <div class="elderlyBottom">
        <div class="elderlyBottom-middle">
          <!-- 订单列表 -->
          <OrderList @serviceItems="serviceItems"></OrderList>
        </div>
        <div class="elderlyBottom-right">
          <!-- 订单详情 -->
          <orderDetail :orderDetail="orderDetail"></orderDetail>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import basicInformation from './components/basicInformation.vue'
import subsidyInformation from './components/subsidyInformation.vue'
import Histogram from './components/Histogram.vue'
import LineChart from './components/LineChart.vue'
import ResidentialAddress from './components/ResidentialAddress.vue'
import socialRelations from './components/socialRelations.vue'
import OrderList from './components/OrderList.vue'
import orderDetail from './components/orderDetail.vue'
import { mapGetters } from 'vuex'
import {
  getStaticMessage,
  getSubsidyInfo,
  getOrderDetail,
} from '@/api/elderlyRecords/elderlyInformation.js'
export default {
  components: {
    basicInformation,
    subsidyInformation,
    Histogram,
    LineChart,
    ResidentialAddress,
    socialRelations,
    OrderList,
    orderDetail,
  },
  // 注册组件
  data() {
    return {
      staticInfo: {},
      subsidyInfo: {},
      orderDetail: {},
      //全屏
      isFullscreen: false,
    }
  },
  computed: {
    customerGlobalId() {
      return this.$route.query.customerGlobalId
    },
    contractGlobalId() {
      return this.$route.query.contractGlobalId
    },
  },
  created() {
    if (this.customerGlobalId && this.contractGlobalId) {
      this.init()
    } else {
    }
  },
  mounted() {},
  methods: {
    // 全屏
    buttoncli() {
      //   判断浏览器是否支持全屏
      if (!screenfull.isEnabled) {
        this.$message({
          message: '你的浏览器不支持全屏',
          type: 'warning',
        })
        return false
      }
      // 将样式改为锁屏
      if (this.isFullscreen == false) {
        //   打开全屏
        this.isFullscreen = true
        screenfull.toggle()
      } else {
        this.isFullscreen = false
        screenfull.toggle()
      }
    },
    back() {
      this.$router.go(-1)
    },
    init() {
      // 获取基础信息
      getStaticMessage(this.customerGlobalId).then((res) => {
        if (res.code == 0) {
          this.staticInfo = res.data
        }
      })
    },
    serviceItems(orderNo) {
      getOrderDetail(orderNo).then((res) => {
        if (res.code == 0) {
          this.orderDetail = res.data
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.viewport_cs {
  background: url('~@/assets/images/zhangzhe/zehngcai/bg.png') no-repeat 0 0 / contain;
  background-size: 100% 100%;
  
  height: 45rem;
}
.viewport {
  min-height: 780px;
  padding: 0;
  max-width: 1888px;
  min-width: 780px;
  margin: 0 auto;
  box-sizing: border-box;
}
.closeImage {
  position: absolute;
  left: 0.666667rem;
  top: 0.91666667rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 1.3333rem;
    width: 1.3333rem;
  }
  span {
    font-size: 1rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #69c1f5;
    background: linear-gradient(0deg, #ffffff 0%, #ffdf49 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 1.4rem;
    width: 30rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      white-space: inherit;
    }
  }
}
.orderTop {
  background: url('~@/assets/images/zhangzhe/zehngcai/zc_top.png') 0 0 no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1920/76;
  position: relative;
}
.orderTop > span {
  margin-top: 0.33333333rem;
  font-size: 1.33rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  background: linear-gradient(0deg, #ffffff 0%, #ffdf49 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.elderlyTop {
  background: url('~@/assets/images/zhangzhe/zehngcai/bg1.png');
  background-size: 100% 100%;
  //   height: 18.66666667rem;
  //   margin-top: 0.416667rem;
  width: 39.125rem;
  height: 18.66rem;
  .elderlyTop-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.416rem;
    margin-top: 1.3rem;
  }
  .elderlyTop-middle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .elderlyTop-middle-top {
      width: 100%;
      height: 7.5833333rem;
    }
  }
}
.elderlyTop-middle-bottom {
  width: 100%;
  height: 10.66667rem;
}
.elderlyTop-right {
  background: url('~@/assets/images/zhangzhe/zehngcai/bg2.png');
  background-size: 100% 100%;
  width: 39.125rem;
  height: 18.66rem;
}
.elderlyBottom {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 0.416rem;
  .elderlyBottom-middle {
    width: 39.125rem;
    height: 21.666rem;
  }
  .elderlyBottom-right {
    width: 39.125rem;
    height: 21.666rem;
  }
}
.ds-center-tip {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 10px;
}
.ds-guanbi {
  position: absolute;
  right: 1rem;
  top: 0.7rem;
}
</style>
<style lang="scss"></style>
