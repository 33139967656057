<template>
  <div class="basic-message">
    <div class="service_detail">
      <div class="service_line">
        <div class="service_line-special">
          <div class="service_line_label">
            服务时长：
          </div>
          <div>{{ serviceItems.serviceDuration }} 分钟</div>
        </div>
        <div class="service_line-special">
          <div class="service_line_label">
            服务人员：
          </div>
          <div>
            {{ serviceItems.staffName }}
          </div>
        </div>
      </div>
      <div class="service_line">
        <div class="service_line_label">服务地址：</div>
        <div>{{ serviceItems.serviceAddress }}</div>
      </div>
      <div class="service_line">
        <div class="service_line_labels">
          服务项目：
        </div>
        <!-- <div style="flex: 1;overflow: hidden;">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              :label="item.itemName"
              :name="item.itemNo"
              v-for="(item, index) in orderDetailMessage.items"
              :key="index"
            ></el-tab-pane>
          </el-tabs>
        </div> -->
        <div class="service_list-item">
          <div
            :class="ac_name == index ? 'ac_name1' : 'ac_name2'"
            v-for="(item, index) in orderDetailMessage.items"
            :key="index"
            @click="handleClick(index, item)"
          >
            {{ item.serviceName }}
          </div>
        </div>
      </div>
      <div class="ds-cen-add">
        <div class="service_line">
          <div class="service_line_label">
            开始服务图片：
          </div>
          <div class="img_swiper">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in beginImgUrls"
                  :key="index"
                >
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="item"
                    fit="cover"
                    :preview-src-list="beginImgUrls"
                  >
                  </el-image>
                </div>
              </div>
              <!-- 如果需要导航按钮 -->
              <!-- <div class="swiper-button-prev" slot="button-prev">
              <img src="../../../imgs/mengzi/order/icon_left.png" alt="" />
            </div>
            <div class="swiper-button-next" slot="button-next">
              <img src="../../../imgs/mengzi/order/icon_right.png" alt="" />
            </div> -->
            </div>
          </div>
        </div>
        <div class="service_line">
          <div class="service_line_label">
            完成服务图片：
          </div>
          <div class="img_swiper">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in endImgUrls"
                  :key="index"
                >
                  <el-image
                    style="width:4.166rem; height:4.166rem"
                    :src="item"
                    fit="cover"
                    :preview-src-list="endImgUrls"
                  >
                  </el-image>
                </div>
              </div>
              <!-- 如果需要导航按钮 -->
              <!-- <div class="swiper-button-prev" slot="button-prev">
              <img src="../../../imgs/mengzi/order/icon_left.png" alt="" />
            </div>
            <div class="swiper-button-next" slot="button-next">
              <img src="../../../imgs/mengzi/order/icon_right.png" alt="" />
            </div> -->
            </div>
          </div>
        </div>
        <!-- <div class="service_line">
        <div class="service_line_label">
          服务满意度：
        </div>
        <div v-if="orderDetailMessage.score">
          <el-rate
            v-model="orderDetailMessage.score"
            show-text
            disabled
            :texts="text"
          >
          </el-rate>
        </div>
      </div> -->
        <div class="service_line">
          <div class="service_line_label">
            评价视频：
          </div>
          <div v-if="orderDetailMessage.evaluateVideoUrl">
            <video
              :src="orderDetailMessage.evaluateVideoUrl"
              width="100"
              height="100"
              controls
            ></video>
          </div>
        </div>
      </div>
      <div class="service_line" v-if="orderDetailMessage.deviceGroup">
        <div class="service_line_labels" style="margin-top:0.2rem">
          绑定设备：
        </div>
        <div class="ds-cen-ssage">
          <div
            :class="t_pment == index ? 'ac_name1' : 'ac_name2'"
            v-for="(item, index) in orderDetailMessage.deviceGroup"
            :key="index"
            @click="btn_ment(index, item.devices)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="service_line" v-if="orderDetailMessage.deviceGroup">
        <div class="service_line_labels">
          设备列表：
        </div>
        <div class="ds-cen-list">
          <div
            class="ds-cen-device"
            v-for="(item, index) in deviceList"
            :key="index"
          >
            <span class="ds-cen-shebei">设备号：{{ item.sn }}</span>
            <span class="ds-weizhi">位置：{{ item.deviceAreaMark }}</span>
            <span class="ds-bangding">绑定时间：{{ item.bindTime }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from 'swiper'
export default {
  data() {
    return {
      deviceList: [],
      text: ['非常差', '差', '一般', '满意', '非常满意'],
      orderDetailMessage: {
        customerName: '',
        age: '',
        idCard: '',
        subsidyAmount: '',
        remainAmount: '',
        score: null,
        evaluateVideoUrl: '',
        items: [],
      },
      serviceItems: {
        serviceDuration: '',
        staffName: '',
        serviceAddress: '',
        itemNo: '',
        endImgUrls: [],
        beginImgUrls: [],
      },
      endImgUrls: [],
      beginImgUrls: [],
      activeName: '',
      ac_name: '',
      t_pment: 0,
    }
  },
  components: {
    Swiper,
  },
  props: {
    orderDetail: {
      type: Object,
      default: {},
    },
  },
  watch: {
    orderDetail: {
      handler() {
        if (Object.getOwnPropertyNames(this.orderDetail).length > 1) {
          console.log(this.orderDetail, 1111)
          this.t_pment = 0
          this.orderDetailMessage = this.orderDetail
          if (this.orderDetail.deviceGroup) {
            this.deviceList = this.orderDetail.deviceGroup[0].devices
          }
          if (this.orderDetailMessage.items.length > 0) {
            this.serviceItems = this.orderDetailMessage.items[0]
            this.activeName = this.orderDetailMessage.items[0].itemNo
            if (this.serviceItems.beginImgUrls != null) {
              this.beginImgUrls = this.serviceItems.beginImgUrls.split(',')
              this.beginImgUrls.splice(2)
            } else {
              this.beginImgUrls = []
            }
            if (this.serviceItems.endImgUrls != null) {
              this.endImgUrls = this.serviceItems.endImgUrls.split(',')
              this.endImgUrls.splice(2)
            } else {
              this.endImgUrls = []
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  mounted() {},
  methods: {
    btn_ment(e, i) {
      this.deviceList = i
      this.t_pment = e
    },
    handleClick(i, val) {
      this.ac_name = i
      const data = this.orderDetailMessage.items.filter((item) => {
        if (item.itemNo == val.itemNo) {
          return item
        }
      })
      this.serviceItems = data[0]
      if (this.serviceItems.beginImgUrls != null) {
        this.beginImgUrls = this.serviceItems.beginImgUrls.split(',')
      } else {
        this.beginImgUrls = []
      }
      if (this.serviceItems.endImgUrls != null) {
        this.endImgUrls = this.serviceItems.endImgUrls.split(',')
      } else {
        this.endImgUrls = []
      }
    },
  },
}
</script>

<style scoped lang="scss">
.basic-message {
  background: url('~@/assets/images/zhangzhe/zehngcai/bg4.png') 0 0 no-repeat;
  background-size: 100% 100%;
  //   box-shadow: 0 0 0 0.5px #0d90ff, inset 0 0 30px rgba(13, 144, 255, 0.4);
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0.416rem 0.416rem 1.8rem 0.416rem;
}
.service_detail {
  margin: 0 auto;
  margin-top: 1.4rem;
  width: 100%;
  height: 100%;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  border: 1px solid #213060;
  .service_detail_title {
    margin-top: 0.75rem;
    font-size: 0.67rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #6ecdff;
    margin-bottom: 0.58rem;
  }
  .service_line {
    margin-top: 0.7rem;
    font-size: 0.5833333rem;
    // margin-bottom: 0.7rem;
    font-weight: 400;
    color: #8eb8e6;
    display: flex;
    .img_swiper {
      width: 4.166rem;
      height: 4.166rem;
      .swiper-container {
        position: relative;
        height: 3rem;
        padding-left: 15px;
        padding-right: 20px;
      }
    }

    .service_line-special {
      display: flex;
      &:nth-child(2) {
        margin-left: 13.25rem;
      }
    }
    .service_line_labels {
      font-size: 0.583rem;
      font-weight: 400;
      color: #b4c6fe;
      opacity: 0.6;
      width: 4.78rem;
      text-align: right;
      float: left;
      line-height: 0.8rem;
    }
    .service_list-item {
      display: flex;
      flex-wrap: wrap;
      // padding-left: 10rem;
    }
    .service_line_label {
      font-size: 0.583rem;
      font-weight: 400;
      color: #b4c6fe;
      width: 4.78rem;
      text-align: right;
      opacity: 0.6;
    }
    ::v-deep .el-tabs__nav-next {
      line-height: 1rem;
    }
    ::v-deep .el-tabs__nav-prev {
      line-height: 1rem;
    }
    ::v-deep .el-tabs__item {
      font-size: 0.5833333rem;
      height: 1rem;
      line-height: 0.67rem;
      color: #8eb8e6;
    }

    ::v-deep .el-tabs__active-bar {
      background-color: #ff9e14 !important;
    }
    ::v-deep .is-active {
      color: #ff9e14;
    }
    ::v-deep .el-tabs__nav-wrap::after {
      position: static !important;
    }
    ::v-deep .el-tabs__header {
      margin: 0;
    }
  }
}
.ds-cen-add {
  display: flex;
}
.ds-cen-ssage {
  width: 80%;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}
.ac_name1 {
  padding: 0 0.3rem 0 0.3rem;
  height: 0.91rem;
  line-height: 0.91rem;
  background: rgba(255, 133, 41, 0.2);
  border-radius: 2px;
  border: 1px solid #ff8529;
  font-size: 0.5833rem;
  font-weight: bold;
  color: #ff8529;
  margin-left: 0.33rem;
}
.ac_name2 {
  padding: 0 0.3rem 0 0.3rem;
  height: 0.91rem;
  line-height: 0.91rem;
  border-radius: 2px;
  font-size: 0.5833rem;
  font-weight: 400;
  color: #8eb8e6;
  margin-left: 0.33rem;
  &:hover {
    background: #1b2551;
  }
}
</style>
<style lang="scss" scoped>
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background: none;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background: none;
}
.swiper-button-prev {
  position: absolute;
  left: 0px;
}
.swiper-button-next {
  position: absolute;
  right: -18px;
}
.swiper-button-prev,
.swiper-button-next {
  margin-top: -11px;
}
.ds-cen-list {
  display: flex;
  width: 85%;
  flex-direction: column;
  font-size: 0.58333rem;
  font-weight: 400;
  color: #8eb8e6;
  height: 7rem;
  overflow-y: auto;
}
.ds-cen-list::-webkit-scrollbar {
  display: none;
}
.ds-cen-device {
  display: flex;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #1c3269;
}
.ds-cen-device:not(:first-child) {
  margin-top: 0.5rem;
}
.ds-weizhi {
  display: block;
  width: 100px;
  margin-left: 3.33rem;
}
.ds-bangding {
  margin-left: 3.33rem;
}
.ds-cen-shebei {
  display: block;
  width: 8.33rem;
}
</style>
