<template>
  <div class="basic-message">
    <el-form ref="form">
      <!-- <el-form-item label="老人姓名 : ">
        {{ staticInfo.name || '-' }}
      </el-form-item>
      <el-form-item label="性别 : ">
        {{ staticInfo.genderMark || '-' }}
      </el-form-item> -->
      <div class="ds-cen-ssime">
        <img src="@/assets/images/zhangzhe/zehngcai/name.png" alt="" />
        <span>{{ staticInfo.name || '-' }}</span>
        <span> {{ staticInfo.gender==0?'女':'男' }}</span>
      </div>
      <el-form-item label="年龄 : ">
        {{ staticInfo.age || '-' }}
      </el-form-item>
      <el-form-item label="身份证号 : ">
        {{ staticInfo.idCard || '-' }}
      </el-form-item>
      <el-form-item label="联系电话 : ">
        {{ staticInfo.phone || '-' }}
      </el-form-item>
      <!-- <el-form-item label="民族 : ">{{
        staticInfo.nationMark || '-'
      }}</el-form-item>
      <el-form-item label="户籍所在地 : " class="address">
        {{ staticInfo.registeredAddress || '-' }}
      </el-form-item>
      <el-form-item label="户口性质 : ">
        {{ staticInfo.censusMark || '-' }}</el-form-item
      >
      <el-form-item label="婚姻状况 : ">
        {{ staticInfo.marital || '-' }}</el-form-item
      >
      <el-form-item label="政治面貌 : "
        >{{ staticInfo.political || '-' }}
      </el-form-item> -->
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    staticInfo: {
      type: Object,
    },
  },
  computed: {},
  created() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.basic-message {
  //   background: rgba(0, 0, 0, 0.2);
  //   border: 1px solid #0d90ff;
  //   background: url('../@/assets/images/mengzi/elderly/basicInformation.png') 0 0
  //     no-repeat;
  //   background-size: 100% 100%;
  //   box-shadow: 0 0 0 0.5px #0d90ff, inset 0 0 30px rgba(13, 144, 255, 0.4);
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background: rgba(5,13,38,0.31);
  overflow: hidden;
}
::v-deep .el-form {
  width: 11.166rem;
    height: 6.75rem;
  background: rgba(5, 13, 38, 0.31);
  margin: 0 auto;
  //   margin-top: 1.8333333rem;
  border: 1px solid #213060;
  //   padding-top: 0.4rem;
  box-sizing: border-box;
 padding-left: 0.625rem;
}
::v-deep .el-form-item {
  margin: 0;
}

::v-deep .el-form-item__label {
  font-size: 0.5833333rem;
  font-weight: 400;
  padding: 0;
  line-height: 1.375rem;
font-weight: 400;
color: #B4C6FE;
  width: 2.66rem;
  text-align: right;
  opacity: 0.6;
}

::v-deep .el-form-item__content {
margin-left: 3rem;
  font-size: 0.5833333rem;
font-weight: 400;
color: #8EB8E6;
  line-height: 1.375rem;
  
}
.address {
  ::v-deep .el-form-item__content {
    font-size: 0.5833333rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8eb8e6;
    line-height: 1.375rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding-right: 0.3rem;
  }
}
.ds-cen-ssime {
  display: flex;
  align-items: center;
  margin-top: 0.583rem;
  margin-left: 1rem;
  span {
    font-size: 0.833rem;
    font-weight: bold;
    color: #8eb8e6;
    margin-left: 0.375rem;
  }
}
</style>
