<template>
  <div class="basic-message">
    <div v-if="serviceCount.length == 0" class="emptyMessage">
      暂无数据
    </div>
    <div
      v-if="serviceCount.length > 0"
      id="container"
      style="height: 15.083333rem;width: 28.583333;margin: 0 auto;"
    ></div>
  </div>
</template>

<script>
import { serviceItemCount } from '@/api/elderlyRecords/elderlyInformation.js'
import { mapGetters } from 'vuex'
//引入echarts
let echarts = require('echarts')
export default {
  data() {
    return {
      serviceCount: [],
    }
  },
  props: {},
  computed: {
    customerGlobalId() {
      return this.$route.query.customerGlobalId
    },
    contractGlobalId() {
      return this.$route.query.contractGlobalId
    },
  },
  methods: {
    init() {
      //   let data = [
      //     [
      //       '06:00',
      //       '07:00',
      //       '08:00',
      //       '09:00',
      //       '10:00',
      //       '11:00',
      //       '12:00',
      //       '13:00',
      //       '14:00',
      //       '15:00',
      //       '16:00',
      //       '17:00',
      //     ],
      //     [5, 40, 30, 40, 20, 28, 32, 22, 18, 30, 33, 30],
      //   ]
      const serviceCount = this.serviceCount.map((item) => {
        return item.serviceCount
      })
      const serviceDate = this.serviceCount.map((item) => {
        return item.serviceDate
      })
      const serviceNames = this.serviceCount.map((item) => {
        return item.serviceNames
      })
      console.log(serviceCount, serviceDate, serviceNames)
      let dom = document.getElementById('container')
      let myChart = echarts.init(dom)
      let option = {
        //dataZoom-inside 内置型数据区域缩放组件 所谓内置 1平移：在坐标系中滑动拖拽进行数据区域平移。2缩放：PC端：鼠标在坐标系范围内滚轮滚动（MAC触控板类同;移动端：在移动端触屏上，支持两指滑动缩放。
        dataZoom: [
          {
            type: 'inside', //1平移 缩放
            throttle: '50', //设置触发视图刷新的频率。单位为毫秒（ms）。
            minValueSpan: 6, //用于限制窗口大小的最小值,在类目轴上可以设置为 5 表示 5 个类目
            start: 1, //数据窗口范围的起始百分比 范围是：0 ~ 100。表示 0% ~ 100%。
            end: 50, //数据窗口范围的结束百分比。范围是：0 ~ 100。
            zoomLock: true, //如果设置为 true 则锁定选择区域的大小，也就是说，只能平移，不能缩放。
          },
        ],
        // 主要用来控制图表四周留白

        tooltip: {
          trigger: 'axis',
          //   showContent: false, //关闭原有样式
          enterable: true,
          backgroundColor: 'transparent', //设置背景图片 rgba格式
          axisPointer: {
            lineStyle: {
              width: 2,
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255,123,58,0)', // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(255,210,0,1)', // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255,123,58,0)', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
          //   position: 'top',
          formatter: function(params) {
            //return回调一个模板字符串，自定义提示框的形状
            return `
             <div class="chartTooltipBox">
                <div class='chartTooltip'>
                   <p class='serviceDate'>${
                     serviceDate[params[0].dataIndex]
                   }</p>
                   <p class='serviceCount'>服务次数：
                    <span style='color:#FF8529'>${
                      serviceCount[params[0].dataIndex]
                    }</span>
                   </p>
                   <p class='serviceName'>服务项目: <br />
                     ${serviceNames[params[0].dataIndex]}
                    </p>
                </div>
             </div>
              `
          },
        },
        grid: {
          //调整地图位置
          left: '3%',
          right: '4%',
          bottom: '0%',
          top: '3%',
          containLabel: true,
        },
        xAxis: {
          //横坐标样式
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: '#8EB8E6',
              fontSize: 12,
            },
          },
          data: serviceDate,
          axisLine: {
            lineStyle: {
              color: '#394788',
            },
          },
        },
        yAxis: {
          axisTick: {
            show: false,
          },
          //纵坐标样式
          splitLine: {
            show: true,
            lineStyle: { type: 'dashed', color: '#394788' },
          },
          axisLabel: {
            textStyle: {
              color: '#8EB8E6',
              fontSize: 14,
            },
          },
          axisLine: {
            lineStyle: {
              color: '#394788',
            },
          },
        },
        graphic: [
          //地图的背景  这里放的是图片，也可以是纯颜色背景
          {
            type: 'image',
            style: {
              backgroundColor: '#ccc',
              x: 70,
              y: 35,
              width: 680,
              height: 220,
            },
          },
        ],
        series: [
          {
            name: '数量',
            type: 'line',
            areaStyle: {
              //折线区域颜色
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //设置折线渐变色
                { offset: 0, color: 'rgba(255,123,58,.4)' },
                { offset: 0.3, color: 'rgba(255,123,58,.3)' },
                { offset: 0.5, color: 'rgba(255,123,58,.2)' },
                { offset: 1, color: 'rgba(255,123,58,.1)' },
              ]),
            },
            showSymbol: true,
            symbol: 'circle', //拐点的形状
            itemStyle: {
              normal: {
                lineStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    //设置折线渐变色
                    { offset: 0, color: '#FF7B3A' },
                    { offset: 1, color: '#FF7B3A' },
                  ]),
                },
                color: '#FF7B3A', //这里设置的拐点颜色
                borderColor: '#FF7B3A', //  拐点边框颜色
                borderWidth: 6, //  拐点边框宽度
              },
            },
            emphasis: {},
            data: serviceCount,
          },
        ],
      }
      myChart.setOption(option)
    },
  },
  mounted() {
    const data = {
      customerGlobalId: this.customerGlobalId,
      contractGlobalId: this.contractGlobalId,
    }
    serviceItemCount(data).then((res) => {
      if (res.code == 0) {
        this.serviceCount = res.data
        if (this.serviceCount.length > 0) {
          this.$nextTick(() => {
            this.init()
          })
        }
      }
    })
  },
}
</script>

<style scoped lang="scss">
.basic-message {
  //   background: url('../../../imgs/zhangzhe/zehngcai/bg2.png') 0 0 no-repeat;
  //   background-size: 100% 100%;
  //   box-shadow: 0 0 0 0.5px #0d90ff, inset 0 0 30px rgba(13, 144, 255, 0.4);
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}
#container {
  margin-top: 2.25rem !important;
}
</style>
<style lang="scss">
.chartTooltipBox {
  box-sizing: border-box;
  height: 8rem;
  display: block;
  width: 100%;
  background: rgba(4, 9, 41, 0.95);
  box-shadow: inset 0px 0px 24px 0px rgba(0, 122, 255, 0.81);
  border-radius: 2px;
  //   background: url(../../../imgs/mengzi/elderly/tootilp.png) 0 0 no-repeat;
  border: 1px solid #0c87ff;
  .chartTooltip {
    box-sizing: border-box;
    padding-top: 0.4rem;
    height: 94%;
    width: 100%;
    padding-left: 0.3rem;
    margin: 0 auto;
    white-space: normal;
    word-break: break-all;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: #2e3c66;
      border-radius: 5px;
    }
  }
}
.serviceDate {
  font-size: 0.75rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-top: 0.1rem;
}
.serviceCount {
  font-size: 0.5833333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  margin-top: 0.1rem;
}
.serviceName {
  font-size: 0.5833333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  margin-top: 0.1rem;
}
.emptyMessage {
  position: absolute;
  font-size: 1.2rem;
  color: #2d3769;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
