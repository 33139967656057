import request from '@/utils/request'
// 查询老人基础模块信息
export const getStaticMessage = (customerGlobalId) =>
  request({
    url: `/hp/platform/data/v1/screen/gov/customer/detail/info?customerGlobalId=${customerGlobalId}`,
    method: 'get',
  })

//   查询补助金额信息模块
export const getSubsidyInfo = (data) =>
  request({
    url: '/hp/platform/data/v1/screen/gov/customer/subsidy_info',
    method: 'get',
    params: data,
  })

//   服务单柱状图
export const serviceCount = (data) =>
  request({
    url: `/hp/platform/data/v1/screen/gov/statistics/customer/service-item?customerGlobalId=${data.customerGlobalId}&contractGlobalId=${data.contractGlobalId}`,
    method: 'get',
  })

//   服务折线图
export const serviceItemCount = (data) =>
  request({
    url: `/hp/platform/data/v1/screen/gov/statistics/customer/item/time?customerGlobalId=${data.customerGlobalId}&contractGlobalId=${data.contractGlobalId}`,
    method: 'get',
  })

//   订单分页
export const orderPage = (data) =>
  request({
    url: '/hp/platform/data/v1/screen/gov/order-page',
    method: 'post',
    data,
  })
// 获取订单详情
export const getOrderDetail = (orderNo) =>
  request({
    url: '/hp/platform/data/v1/screen/gov/order-detail?orderNo=' + orderNo,
    method: 'get',
  })
// 根据合同id老人id查询补助信息
export const getOrdersubsidy = (orderNo) =>
  request({
    url: `/hp/platform/data/v1/screen/gov/contract/screen/customer_subsidy_info?customerGlobalId=${orderNo.customerGlobalId}&contractGlobalId=${orderNo.contractGlobalId}`,
    method: 'get',
  })
