<template>
  <div class="basic-message">
    <div class="orderList">
      <div class="orderTable">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          :header-cell-style="{
            background: '#17295D',
            color: '#538FBD',
            height: '2.08333333rem',
          }"
          @row-click="rowClick"
          :row-style="tableRowStyle"
          :row-class-name="tableRowClassName"
        >
          <el-table-column prop="orderNo" label="订单编号"> </el-table-column>
          <el-table-column prop="appointmentTime" label="服务时间">
          </el-table-column>
          <el-table-column prop="payAmount" label="订单金额" width="200">
            <template slot-scope="scope"> ¥{{ scope.row.payAmount }} </template>
          </el-table-column>
          <el-table-column prop="payAmount" label="政府补助余额" width="120">
            <template slot-scope="scope">
              ¥{{ scope.row.remainAmount }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="5"
          layout="total, prev, pager, next, jumper"
          :total="total"
          background
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { orderPage } from '@/api/elderlyRecords/elderlyInformation.js'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      tableData: [],
      page: 1,
      perPage: 5,
      total: 1,
      getIndex: 0,
    }
  },
  props: {},
  computed: {
    customerGlobalId() {
      return this.$route.query.customerGlobalId
    },
    contractGlobalId() {
      return this.$route.query.contractGlobalId
    },
  },
  methods: {
    rowClick(row) {
      this.row = row
      this.getIndex = row.index
      this.$emit('serviceItems', row.orderNo)
    },
    handleCurrentChange(data) {
      console.log(data)
      this.page = data
      this.init()
    },
    tableRowStyle({ row, rowIndex }) {
      if (this.getIndex === rowIndex) {
        return {
          background: 'rgba(255,133,41,0.2)',
          //   'box-shadow': '0 0 0 0px #ff9e14,inset 0 0 10px #ff9e14',
          'border-bottom': '2px solid #FF8529 !important',
          color: 'rgb(255,158,20) !important',
        }
      }
    },
    tableRowClassName({ row, rowIndex }) {
      //把每一行的索引放进row
      row.index = rowIndex
    },
    init() {
      const data = {
        page: this.page,
        perPage: this.perPage,
        customerGlobalId: this.customerGlobalId,
        contractGlobalId: this.contractGlobalId,
      }
      orderPage(data).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data
          this.total = res.total
          if (res.data.length > 0) {
            this.$emit('serviceItems', res.data[0].orderNo)
          }
        }
      })
    },
  },
  created() {
    this.init()
  },
}
</script>

<style scoped lang="scss">
.basic-message {
  background: url('~@/assets/images/zhangzhe/zehngcai/bg3.png') 0 0 no-repeat;
  background-size: 100% 100%;
  //   box-shadow: 0 0 0 0.5px #0d90ff, inset 0 0 30px rgba(13, 144, 255, 0.4);
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0.416rem;
}
.orderList {
  height: 90%;
  width: 100%;
  margin: 0 auto;
  margin-top: 1.4rem;

  padding-right: 0.416rem;
  display: flex;
  flex-direction: column;
  .orderTable {
    &::-webkit-scrollbar {
      width: 0;
    }
    ::v-deep .el-table,
    .el-table tr,
    .el-table td,
    .el-table th {
      background-color: transparent !important;
    }
    overflow: auto;
    flex: 1;
    .el-table {
      ::v-deep td {
        height: 3.08333333rem;
        border: none;
      }
    }
    ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
      background: transparent;
    }
    ::v-deep .el-table td,
    ::v-deep .el-table th.is-leaf {
      border-bottom: 1px solid #1c3269;
    }

    ::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
      background: transparent;
    }
    ::v-deep .el-table__row {
      cursor: pointer;
    }
    ::v-deep .el-table th,
    ::v-deep .el-table tr {
      background: transparent;
      color: #8eb8e6;
    }
  }
  .page {
    ::v-deep .el-input__inner {
      background: transparent;
      border: 1px solid #00d5ff;
      color: #4dc2ff;
    }

    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
  }
}
.el-table::before {
  height: 0px;
}
</style>
