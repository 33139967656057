<template>
  <div class="basic-message">
    <div class="emptyMessage" v-if="service.length == 0">
      暂无数据
    </div>
    <div
      v-if="service.length > 0"
      id="main1"
      style=" width: 100%;height:8.0416rem;margin-top: 1rem;"
    ></div>
  </div>
</template>

<script>
import { serviceCount } from '@/api/elderlyRecords/elderlyInformation.js'
import { mapGetters } from 'vuex'
let echarts = require('echarts')
export default {
  data() {
    return {
      service: [],
    }
  },
  props: {},
  computed: {
    customerGlobalId() {
      return this.$route.query.customerGlobalId
    },
    contractGlobalId() {
      return this.$route.query.contractGlobalId
    },
  },
  methods: {
    init() {
      //基于准备好的Demo,实例化ECharts
      const data = this.service.map((item) => {
        return item.serviceName
      })
      const dataCount = this.service.map((item) => {
        return item.serviceCount
      })
      const myECharts = echarts.init(document.getElementById('main1'))
      const option = {
        xAxis: {
          type: 'category',
          axisLabel: {
            show: true,
            interval: 0, //使x轴上的文字显示完全,
            //设置一行显示几个字,自己设置
            formatter: function(value) {
              if (data.length > 10) {
                if (value.length > 1) {
                  return `${value.slice(0, 1)}`
                }
              } else if (data.length >= 6 && data.length <= 10) {
                if (value.length > 4) {
                  return `${value.slice(0, 4)}...`
                }
              } else if (data.length < 6) {
                if (value.length > 6) {
                  return `${value.slice(0, 6)}...`
                }
              }

              return value
            },
            color: '#8EB8E6',
          },
          axisLine: {
            lineStyle: {
              color: '#394788',
            },
          },
          data: data,
          color: '#394788',
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#8EB8E6',
            fontFamily: 'PingFangSC-Regular, PingFang SC;',
          },
          axisTick: { show: false },
          splitLine: {
            show: true,
            lineStyle: { type: 'dashed', color: '#394788' },
          },
          axisLine: {
            lineStyle: {
              color: '#394788',
            },
          },
        },
        grid: {
          left: '3%',
          right: '3%',
          bottom: '3%',
          top: '10%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            // label: {
            //   show: true,
            //   color: '#8EB8E6',
            // },
          },
          backgroundColor: 'transparent',
          formatter: function(params) {
            // console.log(params)
            return `<div class='histogram'>
                   <p>项目次数：${params[0].data}次</p>
                   <p>项目名称：${params[0].axisValueLabel}</p>
                </div>`
          },
        },
        series: [
          {
            data: dataCount,
            type: 'bar',
            itemStyle: {
              normal: {
                // color: function() {
                //   return (
                //     '#' +
                //     Math.floor(Math.random() * (256 * 256 * 256 - 1)).toString(
                //       16
                //     )
                //   )
                //     },
                color: function(params) {
                  //定制显示（按顺序,可避免clolrList索引超出）
                  var colorList = [
                    '#855BFF',
                    '#F94A36',
                    '#FF5281',
                    '#25CCB7',
                    '#3A98F3',
                    '#39CE54',
                    '#FF925B',
                    '#92d1ea',
                  ]
                  var index =
                    params.dataIndex > colorList.length - 1
                      ? params.dataIndex - colorList.length
                      : params.dataIndex
                  return colorList[index]
                  //随机显示
                  //return "#"+Math.floor(Math.random()*(256*256*256-1)).toString(16);
                },
              },
            },
            label: {
              show: true, // 开启显示
              //   rotate: 70, // 旋转70度
              position: 'top', // 在上方显示
              distance: 10, // 距离图形元素的距离。当 position 为字符描述值（如 'top'、'insideRight'）时候有效。
              verticalAlign: 'middle',
              textStyle: {
                // 数值样式
                color: '#8EB8E6',
                fontSize: 12,
              },
              formatter: function(params) {
                return `${params.data}次`
              },
            },
            barWidth: 36,
          },
        ],
      }
      myECharts.setOption(option)
    },
  },
  mounted() {
    const data = {
      customerGlobalId: this.customerGlobalId,
      contractGlobalId: this.contractGlobalId,
    }
    serviceCount(data).then((res) => {
      if (res.code == 0) {
        this.service = res.data
        if (this.service.length > 0) {
          this.$nextTick(() => {
            this.init()
          })
        }
      }
    })
  },
}
</script>

<style scoped lang="scss">
.basic-message {
  //   background: url('../../../imgs/mengzi/elderly/Histogram.png') 0 0 no-repeat;
  //   background-size: 100% 100%;
  //   box-shadow: 0 0 0 0.5px #0d90ff, inset 0 0 30px rgba(13, 144, 255, 0.4);
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}
</style>
<style lang="scss" scoped>
.histogram {
  padding: 1rem;
  width: 11.375rem;
  height: 5.666666666667rem;
  box-sizing: border-box;
  display: block;
  background: rgba(4, 9, 41, 0.95);
  box-shadow: inset 0px 0px 24px 0px rgba(0, 122, 255, 0.81);
  border-radius: 2px;
  border: 1px solid #0c87ff;
  p {
    white-space: normal;
    word-break: break-all;
    overflow: auto;
    color: #8eb8e6;
    &:last-child {
      margin-top: 0.3rem;
    }
  }
}
.emptyMessage {
  position: absolute;
  font-size: 1.2rem;
  color: #2d3769;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
