<template>
  <div class="basic-message">
    <div class="subsidy-information">
      <div>
        <div style="margin-top: 0.75rem;">
          <span class="ds-cen-tionmy">所属合同：</span>
          <span class="ds-cen-mss">{{contractName }}</span>
        </div>
        <div style="margin-top: 0.833rem;">
          <span class="ds-cen-tionmy">合同类型：</span>
          <span class="ds-cen-mss">{{  contractBusinessMark }}</span>
        </div>
        <div :class="contractBusiness=='SMART_SERVICE'?'subsidy-lists':'subsidy-list'">
          <ul>
            <li>
              <span class="ds-infomu">{{ subsidyInfo.subsidyAmount }}</span>
              <div style="margin-top: 0.2rem;">
                <span class="ds-infomu1">补助金额</span>
                <span
                  class="transfer-record"
                  @mouseenter="handleEnter"
                  @mouseleave="handleLeave"
                  v-if="contractBusiness=='HOME_SERVICE'&&subsidyInfo.giveLog"
                  >转赠记录</span
                >
              </div>
            </li>
            <li>
              <span class="ds-infomu">{{ subsidyInfo.serviceAmount }}</span
              ><span class="ds-infomu1">服务金额</span>
            </li>
            <li>
              <span class="ds-infomu">{{ subsidyInfo.governmentAmount }}</span
              ><span class="ds-infomu1">补助金额余额</span>
            </li>
            <li  v-if="contractBusiness=='SMART_SERVICE'">
              <span  class="ds-infomu">{{ subsidyInfo.deviceNum }}</span
              ><span class="ds-infomu1">绑定设备数</span>
            </li>
            <li>
              <span class="ds-infomu">{{
                subsidyInfo.receivingServiceNum
              }}</span
              ><span class="ds-infomu1">服务次数</span>
            </li>
          </ul>
        </div>
      </div>
      <div
      v-if="contractBusiness=='HOME_SERVICE'"
        style="width: 100%;height:100%;display: flex;flex-direction: column;align-items: center;"
      >
        <div id="containers"></div>
        <div class="speed-of-progress">完成进度</div>
      </div>
    </div>

    <div class="subGift" v-if="flag">
      <div class="triangle"></div>
      <el-form ref="form" :model="subsidyInfo.giveLog" label-width="3.5rem">
        <el-form-item label="转赠人: ">
          {{ subsidyInfo.giveLog.giverName}}
        </el-form-item>
        <el-form-item label="被转赠人: ">
          {{ subsidyInfo.giveLog.beGiveName}}
        </el-form-item>
        <el-form-item label="转赠金额: ">
          ¥{{ subsidyInfo.giveLog.givAmount}}
        </el-form-item>
        <el-form-item label="转赠时间: ">
          {{ subsidyInfo.giveLog.giveTime }}
        </el-form-item>
        <el-form-item label="转赠备注: ">
          {{ subsidyInfo.giveLog.remarks }}
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getOrdersubsidy } from '@/api/elderlyRecords/elderlyInformation.js'
import { mapGetters } from 'vuex'
let echarts = require('echarts')
export default {
  data() {
    return {
      flag: false,
      subsidyInfo: {},
    }
  },
  props: {},
  computed: {
    customerGlobalId() {
      return this.$route.query.customerGlobalId
    },
    contractGlobalId() {
      return this.$route.query.contractGlobalId
      },
      contractName() {
      return this.$route.query.contractName
      },
      contractBusinessMark() {
      return this.$route.query.contractBusinessMark
      },
      contractBusiness() {
      return this.$route.query.contractBusiness
      },
  },
  methods: {
    init() {
      this.$nextTick(() => {
        console.log(this.subsidyInfo.completionProgress)
        let percent = this.subsidyInfo.completionProgress.replace('%', '') / 100
        console.log(percent)
        let dom = document.getElementById('containers')
        let myChart = echarts.init(dom)
        let option = {
          title: {
            text: `${this.subsidyInfo.completionProgress}`,
            x: 'center',
            y: '45%',
            textStyle: {
              color: '#69B1FF',
              fontSize: 14,
            },
          },

          animation: false,
          series: [
            {
              name: 'main',
              type: 'pie',
              radius: ['75%', '55%'],
              label: {
                normal: {
                  show: false,
                },
              },
              data: this.getData(percent),
            },
          ],
        }
        myChart.setOption(option)
      })
    },
    getData(percent) {
      return [
        {
          value: percent,
          itemStyle: {
            normal: {
              color: '#FF6351',
              shadowColor: '#FF6351',
            },
          },
        },
        {
          value: 1 - percent,
          itemStyle: {
            normal: {
              color: '#091A54',
            },
          },
        },
      ]
    },
      handleEnter () {
      this.flag = true
    },
    handleLeave() {
      this.flag = false
    },
  },
  mounted() {
    const data = {
      customerGlobalId: this.customerGlobalId,
      contractGlobalId: this.contractGlobalId,
    }
    //   获取转赠信息
    getOrdersubsidy(data).then((res) => {
      if (res.code == 0) {
          this.subsidyInfo = res.data
        this.init()
      }
    })
  },
}
</script>

<style scoped lang="scss">
.basic-message {
  //   background: url('../../../imgs/mengzi/elderly/subsidyInformation.png') 0 0
  //     no-repeat;
  //   background-size: 100% 100%;
  //   box-shadow: 0 0 0 0.5px #0d90ff, inset 0 0 30px rgba(13, 144, 255, 0.4);
  height: 100%;
  width: 100%;
  background: rgba(5, 13, 38, 0.31);
  box-sizing: border-box;
  position: relative;
  float: left;
  .subsidy-information {
    display: flex;
    margin: 0.4166667rem;
    width: 26.4166667rem;
    height: 6.75rem;
    background: rgba(5, 13, 38, 0.31);
    border-radius: 2px;
    border: 1px solid #213060;
    // align-items: center;
  }
  .subsidy-list {
    width: 20.5rem;
    // height: 100%;
    position: relative;
    margin-top: 0.5rem;
    ul {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 0.75rem;
      }
    }
    .ds-infomu {
      font-size: 1.25rem;
      font-weight: 500;
      color: #b4c6fe;
    }
    .ds-infomu1 {
      font-size: 0.5rem;
      font-weight: 400;
      color: #8eb8e6;
      margin-top: 0.2rem;
    }
    .transfer-record {
      font-size: 0.5rem;
      font-weight: 400;
      color: #ff8529;
      margin-top: 0.1rem;
      margin-left: 0.1rem;
      cursor: pointer;
    }
  }
  .subsidy-lists{
    width:110%;
    // height: 100%;
    position: relative;
    margin-top: 0.75rem;
    ul {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 0.75rem;
      }
    }
    .ds-infomu {
      font-size: 1.25rem;
      font-weight: 500;
      color: #b4c6fe;
    }
    .ds-infomu1 {
      font-size: 0.5rem;
      font-weight: 400;
      color: #8eb8e6;
      margin-top: 0.2rem;
    }
    .transfer-record {
      font-size: 0.5rem;
      font-weight: 400;
      color: #ff8529;
      margin-top: 0.1rem;
      margin-left: 0.1rem;
      cursor: pointer;
    }
  }
  #containers {
    width: 5.166rem !important;
    height: 5.166rem;
  }
  .speed-of-progress {
    font-size: 0.5rem;
    font-weight: 400;
    color: #8eb8e6;
  }
  .ds-cen-tionmy {
    font-size: 0.58rem;
    font-weight: 400;
    color: #b4c6fe;
    opacity: 0.6;
    margin-left: 0.75rem;
  }
  .ds-cen-mss {
    font-size: 0.58rem;
    font-weight: 400;
    color: #8eb8e6;
  }
  .subGift {
    z-index: 9999999 !important;
    width: 11.375rem;
    min-height: 5.666666666667rem;
    background: rgba(4, 9, 41, 0.95);
    box-shadow: inset 0px 0px 24px 0px rgba(0, 122, 255, 0.81);
    border-radius: 2px;
    border: 1px solid #0c87ff;
    position: absolute;
    left: 6rem;
    top: 3.5rem;
    .triangle {
      width: 0.4rem;
      height: 0.8rem;
      background: rgba(4, 9, 41, 0.95);
      box-shadow: inset 0px 0px 24px 0px rgba(0, 122, 255, 0.5);
      clip-path: polygon(0 50%, 100% 100%, 100% 0);
      position: absolute;
      left: -0.35rem;
      top: 50%;
      transform: translateY(-0.45rem);
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 0;
}
::v-deep .el-form {
  margin-top: 0.3rem;
}
::v-deep .el-form-item__label {
  line-height: 1rem;
  font-size: 0.583333333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
}
::v-deep .el-form-item__content {
  line-height: 1rem;
  font-size: 0.583333333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
}
</style>
