<template>
  <div class="basic-message">
    <div class="social-table">
      <el-table
        :data="staticInfo.contacts"
        stripe
        style="width: 100%;"
        :header-cell-style="{
          background: '#17295D',
          color: '#538FBD',
        }"
      >
        <el-table-column prop="name" label="姓名" width="100">
        </el-table-column>
        <el-table-column prop="relationshipMark" label="关系">
        </el-table-column>
        <el-table-column prop="phone" label="联系电话" width="120">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          name: '蔡徐坤',
          gender: '男',
          phone: '15282289392',
        },
        {
          name: '蔡徐坤',
          gender: '男',
          phone: '15282289392',
        },
        {
          name: '蔡徐坤',
          gender: '男',
          phone: '15282289392',
        },
        {
          name: '蔡徐坤',
          gender: '男',
          phone: '15282289392',
        },
        {
          name: '蔡徐坤',
          gender: '男',
          phone: '15282289392',
        },
        {
          name: '蔡徐坤',
          gender: '男',
          phone: '15282289392',
        },
      ],
    }
  },
  props: {
    staticInfo: {
      type: Object,
    },
  },
  computed: {},
  methods: {},
  created() {},
}
</script>

<style scoped lang="scss">
.basic-message {
  background: url('~@/assets/images/mengzi/elderly/socialRelations.png') 0 0
    no-repeat;
  background-size: 100% 100%;
  //   box-shadow: 0 0 0 0.5px #0d90ff, inset 0 0 30px rgba(13, 144, 255, 0.4);
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}
.social-table {
  width: 19rem;
  overflow: auto;
  margin: 0 auto;
  margin-top: 1.70833333rem;
  height: 9.29166667rem;
  &::-webkit-scrollbar {
    width: 0;
  }
}
.social-table {
  ::v-deep .el-table,
  .el-table tr,
  .el-table td,
  .el-table th {
    background-color: transparent !important;
  }
  .el-table {
    ::v-deep td {
      height: 2.2rem;
    }
  }
}
::v-deep .el-table th {
  background-color: transparent;
  color: #4dc2ff;
}
::v-deep .el-table tr {
  background-color: transparent;
  color: #4dc2ff;
}

.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: transparent;
}
.el-table--border:after,
.el-table--group:after,
.el-table:before {
  background-color: #0d618d;
}
.el-table--border,
.el-table--group {
  border-color: #0d618d;
}
::v-deep .el-table td,
::v-deep .el-table th.is-leaf {
  border-bottom: 1px solid #0d618d;
}
::v-deep .el-table td,
::v-deep .el-table th.is-leaf {
  border: none;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: transparent;
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: transparent;
}
</style>
<style>
.el-table--striped .el-table__body tr.el-table__row--striped td {
  background-color: transparent;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: rgba(255, 158, 20, 0.3);
  color: #ff9e14;
  border: 1px solid #ed7301;
  box-shadow: '0 0 0 1px #ff9e14,inset 0 0 1px #ff9e14';
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: transparent;
  border: 1px solid #4dc2ff;
  color: #4dc2ff;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #ff9e14;
}
.el-pagination__total {
  color: #4dc2ff;
}
.el-pagination__jump {
  color: #4dc2ff;
}
.swiper-container {
  --swiper-navigation-size: 0;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background: none;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background: none;
}
.swiper-button-prev {
  position: absolute;
  left: 0px;
}
.swiper-button-next {
  position: absolute;
  right: -18px;
}
.swiper-button-prev,
.swiper-button-next {
  margin-top: -11px;
}
</style>
